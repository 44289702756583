var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "visit"
  }, [_c('Card', [_c('p', {
    staticClass: "card-title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_c('Icon', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "type": "md-map"
    }
  }), _vm._v("每日来访量统计 ")], 1), _c('div', {
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_c('DatePicker', {
    staticStyle: {
      "width": "250px"
    },
    attrs: {
      "options": _vm.options,
      "type": "daterange",
      "placeholder": "选择日期范围"
    }
  })], 1), _c('div', {
    staticStyle: {
      "height": "273px"
    }
  }, [_c('apexchart', {
    ref: "visitChart",
    attrs: {
      "type": "bar",
      "height": "280",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };